<template>
  <a
  class="link"
  :href="path + href"
  @click="$router.push(path)"
  :class="[Color, Size]"
  >
    <span class="link"><slot></slot></span>
      <div class="icon" v-if="Icon" :class="`icon-${Size}`">
          <svg
          viewBox="0 0 20 20"
          :width="iconWidth"
          :height="iconHeight"
          >
            <g :class="Color">
                <slot name="icons"><iArrowRightM/></slot>
            </g>
          </svg> 
      </div>
  </a>
</template>

<script>
import iArrowRightM from '@/assets/img/icons/system/iArrowRightM.vue'

export default {
    name: 'vLink',
    components: {
        iArrowRightM
    },
    props: {
        Size: {
            type: String,
            default: 'l'
        },
        href: {
            type: String,
            default: ''
        },
        path : {
            type: String,
            default: ''
        },
        Color: {
            type: String,
            default: 'primary'
        },
        Icon: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
    .link {
      position: relative;
        cursor: pointer;
        display: inline-flex;
        font-weight: 700;
        line-height: 1.5;
        width: fit-content;  
    }
    .link {
        position: relative;
        cursor: pointer;
        display: inline-flex;
        font-weight: 500;
        line-height: 1.5;
        width: fit-content;
        &:after {
            content: '';
            display: flex;
            position: absolute;
            bottom: 0;
            height: 0.2rem;
            width: 0;
            background: $a-green;
            transition: $main-transition;
        }
        &:hover::after {
            width: 100%;
        }
    }
    .l {
        font-size: $s-20;
    }
    .m {
        font-size: $s-15;
    }
    .primary{
        color: $a-green;
        fill: $white;
    }
    .secondary{
        color: $secondary;
        fill: $white;
    }
    .icon {
        display: flex;
        @include flex-center;
        padding: $s-10;
        background: $a-green;
        margin-left: $s-10;
        &-l {
            height: $s-30;
            width: $s-30;
            border-radius: $s-6;
            padding: $s-4;
        }
        &-m {
            height: $s-20;
            width: $s-20;
            border-radius: $s-4;
            padding: 0.2rem;
        }
        img {
            width: 100%;
            height: 100%;
        }
        }

        @media screen and (max-width: $tablet-landscape-min) {
            .link {
                &:hover::after {
                    width: 0;
                }
            }
        }
</style>