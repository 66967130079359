export const Clients = {
    state: () => ({
        Clients: [
            {link: 'https://megafon.ru', img: 'clients/Megafon.svg', index: '1'},
            {link: 'https://mts.ru', img: 'clients/MTS.svg', index: '2'},
            {link: 'https://novgorodbirch.com/', img: 'clients/NOvgorodBirch.svg', index: '3'},
            {link: 'http://ludilove.ru/', img: 'clients/PeopleLove.svg', index: '4'},
            {link: 'https://rd-house.ru/', img: 'clients/RoschinHouse.svg', index: '5'},
            {link: 'https://www.dns-shop.ru/', img: 'clients/DNS.svg', index: '6'},
            // {link: 'https://emg.fm/', img: 'clients/EGM.svg', index: '7'},
            {link: 'https://mvideo.ru', img: 'clients/M.Video.svg', index: '7'},
            {link: 'http://www.biovat-profil.ru/', img: 'clients/Biovat.svg', index: '8'},
            {link: 'https://www.creditasia.uz/', img: 'clients/Credit-Asia.svg', index: '9'},
            {link: 'https://www.eldorado.ru/', img: 'clients/Eldorado.svg', index: '10'},
            // {link: 'https://radiomayak.ru/', img: 'clients/Mayak.svg', index: '12'},
            {link: 'https://derevovdom.ru/', img: 'clients/RusMaster.svg', index: '11'},
            {link: 'https://sokoldok.ru/', img: 'clients/Sokol.svg', index: '12'},
            {link: 'https://www.timproduct.ru/', img: 'clients/TimProduct.svg', index: '13'},
          ],
    }),
    getters: {
        getClients(state) {
            return state.Clients
        }
    }
}