<template>
  <DefaultLayout>
    <router-view/>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
export default {
  components: {
    DefaultLayout
  }
}
</script>

<style lang="scss">
// @import "@/assets/scss/main.scss";
</style>
