<template>
    <path
      d="M18.28,10l-.45.56-.12.15-3.93,4.91A1,1,0,0,1,13,16a1,1,0,0,1-.62-.22,1,1,0,0,1-.16-1.4L14.92,11H3A1,1,0,0,1,3,9H14.92l-2.7-3.38a1,1,0,1,1,1.56-1.24l3.93,4.91.12.15Z"
    />
</template>

<script>
export default {
    name: "iArrowRightM",
};
</script>