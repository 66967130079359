export const Header = {
    state: () => ({
        navItems: [
            {name: "Главная", link: "/"},
            {name: "Реквизиты", link: "/req"},
        ],
        menuState: false,
    }),
    getters: {
        getNavLinks(state) {
            return state.navItems
        } ,
        getMenuState(state) {
            return state.menuState
        }
    },
    mutations: {
        menuOpen(state) {
            state.menuState = !state.menuState
        }
    },
    actions: {

    }
}