<template>
  <div class="main-wrapper">
    <vHeader />
    <div class="main_layout">
      <Router-view />
    </div>
  </div>
  <vFooter />
  <vCopiright />
</template>

<script>
import vHeader from '@/components/sections/vHeader.vue'
import vFooter from '@/components/sections/vFooter.vue'
import vCopiright from '@/components/sections/vCopiright.vue'

export default {
  components: {
    vHeader,
    vFooter,
    vCopiright
  }
}
</script>

<style lang="scss" scoped>
.main-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
    width: 100%;
}
</style>