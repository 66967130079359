export const Team = ({
    state: () => ({
        team: [
            {mask: 'mask-1', img: 'Dmitriy.jpg', name: 'Дмитрий', position: 'Chief executive officer, основатель'},
            {mask: 'mask-1', img: 'Tanya.jpg', name: 'Таня', position: 'Сhief financial officer'},
            // Maria P
            {mask: 'mask-4', img: 'Masha-2.jpg', name: 'Мария', position: 'Digital artist'},
            {mask: 'mask-5', img: 'Vanya.jpg', name: 'Ваня', position: 'Desktop & Mobile Developer'},
            // Leonid
            {mask: 'mask-1', img: 'Leonid.jpg', name: 'Леонид', position: 'Technical support engineer'},
            // Nastya L
            {mask: 'mask-2', img: 'Nastya-cyp.jpg', name: 'Настя', position: 'Cybercarpenter'},
            {mask: 'mask-6', img: 'Liza.jpg', name: 'Лиза', position: 'Office manager'},
        ],
    }),
    getters: {
        getTeam(state) {
            return state.team
        }
    }
})