<template>
  <footer class="footer pd-t-40 pd-b-40 mr-t-auto" id="footer">
    <div class="wrapper main_layout">
        <!-- About Company Nav -->
            <!-- <nav class="g-2">
                <p class="p-2 mr-b-30 t-w-b">Компания</p>
                <Link 
                Size="m" 
                Color="secondary" 
                class="t-w-m mr-b-15" 
                v-for="nav in navCompany" 
                :key="nav" 
                :href="nav.href" 
                :path="nav.to"
                >{{nav.name}}</Link>
            </nav> -->
        <!-- Work in company Nav  -->
          <!-- <nav class="g-2">
              <p class="p-2 mr-b-30 t-w-b">Работа</p>
              <Link
                @click="setInterships(nav.name)"
                class="mr-b-15"
                Size="m"
                Color="secondary"
                v-for="nav in navWork"
                :key="nav"
                :path="nav.to"
                :href="nav.href"
              >{{nav.name}}</Link>
          </nav> -->
        <!-- Cooperative Nav -->
          <nav class="g-6">
              <p class="p-2 mr-b-30 t-w-b">Сотрудничество</p>
              <Link
                class="mr-b-15"
                Size="m"
                Color="secondary"
                v-for="nav in navCooperation"
                :key="nav"
                :path="nav.to"
                :href="nav.href"
              >{{nav.name}}</Link>
          </nav>
          <!-- Contact Us -->
          <div class="g-6">
            <div class="team_nav">
                <router-link class="team_nav_link" to="/"><img src="@/assets/img/Kit.svg" alt="TP Lab">ТП Кит</router-link>
                <!-- <a class="team_nav_link" href="https://truepositive.ru/"><img src="@/assets/img/Lab.svg" alt="TP Lab">ТП Лаб</a> -->
            </div>
            <!-- Contact Us Button -->
            <navBtn @click="$router.push('/contacts')" :href="'/contacts'" class="btn btn-contact mobile_hidden" >Свяжитесь с нами</navBtn>
          </div>
      </div>
  </footer>
</template>

<script>
import Link from '@/components/ui/buttons/Link.vue'
import navBtn from '@/components/ui/buttons/navBtn.vue'

export default {
    name: 'vFooter',
    components: {
        Link,
        navBtn
    },
    data() {
        return {
            navCompany: [
                {name: 'О компании', to: '/about', href: ''},
                {name: 'Продукты', to: '/products',href: ''},
                {name: 'Технологии', to: '/products', href: '#technology'}
            ],
            navWork: [
                {name: 'Работа в компании', to: '/career', href: ''},
                {name: 'Вакансии', to: '/career', href: '#vacancies'},
                {name: 'Стажировки', to: '/career', href: '#vacancies'}
            ],
            navCooperation: [
                {name: 'Контакты', to: '/contacts', href: ''},
                // {name: 'Реквизиты', to: '/req', href: ''},
            ]
        }
    },
    // methods: {
    //     setInterships(link) {
    //         if (link === "Стажировки") {
    //             this.$store.state.vacancies.tabActive = "interships";
    //             this.activeTab = "interships"
    //         } else {
    //             if (link === "Вакансии") {
    //                 this.$store.state.vacancies.tabActive = "vacancy";
    //                 this.activeTab = "vacancy"
    //             }
    //         }
    //     }
    // }
}
</script>

<style lang="scss" scoped>
    #footer {
        border-top: 1px dashed $light;
        .g-6:nth-child(2) {
            align-items: flex-end;
        }
        .team_nav {
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-end;
            margin-bottom: $s-20;
            .team_nav_link {
                display: inline-flex;
                align-items: center; 
                font-size: 1.8rem; 
                font-weight: 800; 
                &:first-child {margin-right: 2rem;}
                

                img {margin-right: 0.5rem;}
            }
        }
    }
    @media screen and (max-width: $tablet-landscape) {
        #footer {
            .wrapper {
                grid-template-columns: repeat (10, 1fr);
                .g-6:nth-child(1) {grid-column-end: span 4; flex-direction: column;}
                .g-6 {grid-column-end: span 4;}
            }
        }
    }
    @media screen and (max-width: $tablet-landscape-min) {
        #footer {
            padding: 0;
            .wrapper {
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 0;
                .g-6:nth-child(1) {
                    grid-column-end: span 3; 
                    padding: $s-20;
                    &:nth-child(3) {
                        border-right: none;
                    }
                }
                .g-6 {
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    grid-template-columns: span 3; 
                    border-top: 1px dashed $light;
                    padding: $s-40;
                    .team_nav {margin-bottom: 0; margin-right: $s-20;}
                }
            }
        }
    }
    @media screen and (max-width: $phone-landscape) {
        #footer {
            .wrapper {
                grid-template-columns: repeat(4, 1fr);
                .g-6:nth-child(1) {
                    grid-column-end: span 4;
                    &:nth-child(2) {
                        border: none;
                    }
                    &:nth-child(3) {
                        border-top: 1px dashed $light;
                        grid-column-end: span 4;
                    }
                }
                .g-6 {
                    flex-direction: column;
                    padding: $s-20;
                    .team_nav {margin-bottom: $s-20; margin-right: 0;}
                }
            }
        }
    }
</style>