<template>
<a
    :href="href" 
    :class="[Color, Size]"
    class="navBtn"
>
    <slot/>
</a>
</template>

<script>
export default {
    name: 'navBtn',
    props: {
        href: {
            type: String
        },
        Color: {
            type: String,
            default: 'white'
        },
        Size: {
            type: String,
            default: 'm'
        }
    }
}
</script>

<style lang="scss" scoped>
    .navBtn {
        display: flex;
        flex-wrap: nowrap;
        cursor: pointer;
        width: fit-content;
        height: fit-content;
        align-items: center;
        justify-content: space-between;
        font-weight: 800;
        border-radius: $s-120;
        transition: $main-transition;
    }
    .green {
        background-color: 
        $bg-green; 
        color: $white;
        &:hover {
            box-shadow: $shadow-small;
        }
        }
    .white {
        background-color: 
        $bg-white; 
        color: $green;
        &:hover {
            box-shadow: $shadow-small;
        }
        }
    .light {
        background-color: 
        $light; 
        color: $green;
        box-shadow: $shadow-light;
        &:hover {
            box-shadow: $shadow-light-hover;
        }
        }

    .m {padding: $s-15 $s-20; font-size: $s-15;}
    .l {padding: $s-20 $s-20; font-size: $s-20;}
</style>