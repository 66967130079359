import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'ТП Кит',
    component: () => import('@/views/Main.vue'),
    meta: {
      title: 'ТП Кит',
      keywords: ''
    },
  },
  {
    path: '/contacts',
    name: 'Контакты',
    component: () => import('../views/Contacts.vue'),
    meta: {
      title: 'Контакты',
      keywords: ''
    },
  },
  {
    path: '/req',
    name: 'Реквизиты',
    component: () => import('@/views/Requisites.vue'),
    meta: {
      title: 'Реквизиты',
      keywords: ''
    },
  },
  {
    path: '/privacy-policy',
    name: 'Политика конфиденциальности',
    component: () => import('@/views/PrivacyPolicy.vue'),
    meta: {
      title: 'Политика конфиденциальности'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return {top: 0}
  }
})

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  next();
})


export default router
