import { createStore } from 'vuex'
import { Header } from '@/store/header.js'
import { Team } from '@/store/team.js'
import { Partners } from '@/store/partners.js'
import { Products } from '@/store/products.js'
import { Clients } from '@/store/clients.js'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    header: Header,
    team: Team,
    partners: Partners,
    products: Products,
    clients: Clients
  }
})
