<template>
  <section id="copyrights">
      <div class="main_layout flex">
          <!-- <a @click="$router.push('/intellectual-property')" class="nav nav-s p-l">Интеллектуальная собственность </a> -->
          <!-- <a @click="$router.push('/pp')" class="nav nav-s p-l">Политика конфиденциальности  </a> -->
          <a @click="$router.push('/req')" class="nav nav-s p-l">Реквизиты </a>
          <a @click="$router.push('/privacy-policy')" class="nav nav-s p-l">Политика конфиденциальности</a>
          <p class="p-l">© 2019-2022, True Positive Kit ООО "ТП Кит"</p>
      </div>
  </section>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    #copyrights {
        background: $white;
        .flex {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding-top: $s-10;
            padding-bottom: $s-10;
        }
        .nav {
            @include main-tr;
            cursor: pointer;
            &:hover {
                color: $secondary;
            }
        }
        .nav-s {margin-right: $s-20;}
        .p-l:last-child {
            margin-left: auto;
        }
    }
    @media screen and (max-width: $tablet-landscape-min) {
        #copyrights {
            .flex {
               .p-l {margin-left: 0; flex-basis: 100%; margin-top: $s-20;}
            }
        }
    }
    @media screen and (max-width: $phone-landscape) {
        #copyrights {
            .flex {
                justify-content: space-around;
                .nav { flex-basis: auto; margin-top: $s-20;}
                .nav-s {margin-right: $s-10;}
                .p-l {flex-basis: auto;}
            }
        }
    }
    @media screen and (max-width: $phone-portrait) {
        #copyrights {
            .flex {
                .p-l {margin-top: $s-40;}
            }
        }
    }
</style>